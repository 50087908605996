import { defineStore } from 'pinia'
import { ref } from 'vue'
import { type Notification } from '@/interfaces/Notification'
import {NotificationService} from '@/services/NotificationsService'

export const useNotificationStore = defineStore('notificationStore', () => {
  const notificationsState = ref<Notification[] | null>([])
  const getNotifications = async () => {
    return notificationsState.value;
  }

  const setNotifications = (notifications: Notification[]): void => {
    notificationsState.value = notifications
  }

  const addLocalNotification = (notification: Notification): void => {
    notificationsState.value?.push(notification);
  }

  const setNotificationsAsSeen = async () => {
    await NotificationService.setNotificationAsSeen();
    if (notificationsState.value?.length == 0) {
      return
    }

    notificationsState.value?.forEach((notification) => {
      notification.seenOn = new Date()
    })
  }

  return {
    notificationsState,
    setNotifications,
    setNotificationsAsSeen,
    getNotifications,
    addLocalNotification
  }
})
