<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useChecksStore } from '@/stores/checksStore'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import type { CheckOfflineMetadata } from '@/offlineDatabase'

const router = useRouter()
const checksStore = useChecksStore()
const checkOfflineService = CheckOfflineService()

const props = defineProps<{
  checkOfflineMetadata: CheckOfflineMetadata
}>()

defineEmits(['buttonClick', 'onIssueReportSent'])

const onStartCheck = async (checkOfflineMetadata: CheckOfflineMetadata) => {
  if (checkOfflineMetadata.check.isCompleted && !checkOfflineMetadata.check.canBeFinishedMultipleTimes) return;
  if (checkOfflineMetadata.check.canBeFinishedMultipleTimes) {
    await checkOfflineService.startExtraCheck(checkOfflineMetadata)
  } else {
    await checkOfflineService.startNormalCheck(checkOfflineMetadata)
  }
  await router.push('/account/check')
}

const isCheckTypeExtra = (): boolean => {
  return props.checkOfflineMetadata.check.category === 'Extra'
}

const isOverdue = (): boolean => {
  let currentDate = new Date()
  if (props.checkOfflineMetadata.check.category != "Today") return false;

  let timeParts = props.checkOfflineMetadata.check.due.split(':')
  let hours = parseInt(timeParts[0])
  let minutes = parseInt(timeParts[1]) 

  if (hours == 0 && minutes == 0) {
    return false
  }

  currentDate.setHours(hours)
  currentDate.setMinutes(minutes)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  return currentDate < new Date()
}

const removeSecondsAndConvertTo12Hour = (time:string) => {
  var t = time.split(':');
  if (parseInt(t[0]) > 12) {
    return (parseInt(t[0]) - 12) + ':' + t[1] + ' PM';
  }
  return time.split(':').slice(0, 2).join(':') + "AM";
}
</script>

<template>
  <div class="card" @click="onStartCheck($props.checkOfflineMetadata)">
    <div
      :class="['card-sidebar']"
      :style="`background-color: ${checkOfflineMetadata.check.icon.colour} !important`"
    >
      <div class="card-icon-container">
        <i :class="[checkOfflineMetadata.check.icon.icon]"></i>
      </div>
    </div>
    <div class="card-content">
      <el-text v-if="checkOfflineMetadata.check.title" class="card-title" size="large">
        {{ checkOfflineMetadata.check.title }}
      </el-text>
      <el-text v-if="!isOverdue() && !isCheckTypeExtra()" class="card-sub-title"
        >Due {{checkOfflineMetadata.check.days}} at {{ removeSecondsAndConvertTo12Hour(checkOfflineMetadata.check.due) }}
      </el-text>
      <el-text
        v-if="checkOfflineMetadata.check.description"
        class="card-description"
        line-clamp="3"
      >
        {{ checkOfflineMetadata.check.description }}
      </el-text>

      <div
        v-if="
          isOverdue() &&
          !checkOfflineMetadata.check.isCompleted
        "
        class="card-alert-container"
      >
        <img height="24" src="../assets/circle-exclamation-solid.svg" width="auto" />
        <el-text class="card-warning">Late! Was due {{ removeSecondsAndConvertTo12Hour(checkOfflineMetadata.check.due) }}</el-text>
      </div>

      <div v-if="checkOfflineMetadata.check.isCompleted" class="card-success-container">
        <img height="24" src="../assets/check-solid.svg" width="auto" />
        <el-text class="card-success">Completed</el-text>
      </div>

      <el-button
        v-if="
          checkOfflineMetadata.check.isCompleted &&
          checkOfflineMetadata.check.canBeFinishedMultipleTimes
        "
        class="action-button no-hover"
      >
        Start check
      </el-button>

      <el-button
        v-if="!checkOfflineMetadata.check.isCompleted"
        class="action-button no-hover"
      >
        Start check
      </el-button>
    </div>
  </div>
</template>

<style scoped>
.card {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 10px;
  overflow: clip;
  min-width: 320px;
  width: 100%;
  margin-bottom:10px;
}

.card-sidebar {
  background: #151740;
  min-width: 50px;
  min-height: 200px;
  display: flex;
  justify-content: center;
}

.card-sidebar .card-description {
  background: #289548;
}

.card-icon-container {
  background: white;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon-container i {
  color: #0f0f0f;
}

.card-title {
  margin-right: auto;
  font-weight: bold;
  color: #151740;
}

.card-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.card-sub-title {
  margin-right: auto;
  font-weight: bold;
}

.card-description {
  margin-top: 10px;
  justify-content: left;
  width: 100%;
}

.card-alert-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.card-warning {
  text-align: center;
  margin-left: 5px;
  background: white;
  color: grey;
  font-weight: bold;
}

.card-success-container {
  margin-top: auto;
  display: flex;
  flex-direction: row;
}

.card-success {
  margin-left: 5px;
  background: white;
  color: grey;
  font-weight: bold;
}

.action-button {
  margin: auto 0 0;
  border-color: #289548;
  border-radius: 8px;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
}

</style>
