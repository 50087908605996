<script lang="ts" setup>
import { ref, watch } from 'vue'
import type { Issue } from '@/interfaces/Issue'
import { IssueService } from '@/services/IssueService'
import { ElNotification } from 'element-plus'
import {CheckOfflineService} from '@/services/CheckOfflineService'
import { useChecksStore } from '@/stores/checksStore'

const emit = defineEmits(['closeDialog', 'onSend'])

const radio = ref<'high' | 'low'>('low')
const descriptionInput = ref('')
const checkId = ref<string>()
const taskId = ref<string>()
const checkOfflineService = CheckOfflineService();
const checkStore = useChecksStore();
const error = ref(false)
const props = defineProps({
  isOpen: { type: Boolean, required: true },
  title: { type: String },
  description: { type: String },
  hideSeverity: { type: Boolean }
})

const open = ref(false)

watch(
  () => props.isOpen,
  (isOpen) => {
    open.value = isOpen
  }
)

const setCheckId = (newCheckId: string) => {
  checkId.value = newCheckId
}

const setTaskId = (newTaskId: string) => {
  taskId.value = newTaskId
}

defineExpose({ setCheckId, setTaskId })

const onSend = async () => {
  let issueService = new IssueService()

  if (descriptionInput.value == '') {
    error.value = true;
    return
  }

  let issue: Issue = {
    severity: radio.value,
    description: descriptionInput.value,
    checkId: checkId.value == '' ? null : checkId.value,
    taskId: taskId.value == '' ? null : taskId.value
  }
  if (checkId.value != undefined && checkStore.currentCheckMetadata?.guid != null) {
    await checkOfflineService.addIssue(issue, checkStore.currentCheckMetadata?.guid!);

    ElNotification({
      title: 'Success',
      message: 'Corrective actions have been saved',
      type: 'success',
      customClass: 'notification'
    })
    emit('onSend')
  } else {
    let response = await issueService.saveIssue(issue)
    if (response.success) {
      ElNotification({
        title: 'Success',
        message: 'Issue has been saved',
        type: 'success',
        customClass: 'notification'
      })

      emit('onSend')
    } else {
      ElNotification({
        title: 'Failure',
        message: 'Unexpected error occurred',
        type: 'error',
        customClass: 'notification'
      })
    }
  }

  open.value = false
  checkId.value = ''
  taskId.value = ''
  descriptionInput.value = ''
}
</script>

<template>
  <el-dialog
    v-model="open"
    :title="title ? title : 'Report Issue'"
    align-center
    itemref="wrapper"
    width="500"
    @close="$emit('closeDialog')"
  >
    <el-space class="container" direction="vertical" fill>
      <div
        v-if="!hideSeverity"
        style="display: flex; align-items: center; vertical-align: center; justify-items: center"
      >
        <el-text>Severity</el-text>
        <el-radio-group v-model="radio" style="margin-left: 40px">
          <el-radio-button class="high-severity-button" value="High">High</el-radio-button>
          <el-radio-button class="low-severity-button" value="Low">Low</el-radio-button>
        </el-radio-group>
      </div>

      <el-text>{{ description ? description : 'Describe the issue' }}</el-text>
      <div v-if="error" style="color: red">Please enter a description</div>
      <el-input
        v-model="descriptionInput"
        resize="none"
        rows="6"
        style="width: 100%"
        type="textarea"
      />
    </el-space>

    <template #footer>
      <div class="dialog-footer">
        <el-button class="no-hover cancel-button" @click="open = false">Cancel</el-button>
        <el-button class="no-hover send-button" type="primary" @click="onSend"> Send</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
}

.high-severity-button {
  margin: 0;
  --el-radio-button-checked-bg-color: red;
}

.low-severity-button {
  margin: 0;
  --el-radio-button-checked-bg-color: #ffbf00;
}

.send-button {
  background-color: #151740 !important;
  color: white !important;
  border-color: #151740;
  border-radius: 8px;
}

.cancel-button {
  background-color: #5d5d5d !important;
  color: white !important;
  border-radius: 8px;
}
</style>
