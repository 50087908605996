<script lang="ts" setup>
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import InProgressCardComponent from '@/components/InProgressCardComponent.vue'
import { CheckStep } from '@/enums/CheckStep'
import { useChecksStore } from '@/stores/checksStore'

const checksStore = useChecksStore()

const onIssueReportSent = (check: TodaysCheck) => {
  let checkToEdit = checksStore.offlineChecksMetadata.find((c) => c.id == check.id)

  //checkToEdit!.check.issueReportSent = true
}

const areChecksInProgress = () => {
  return checksStore.offlineChecksMetadata.some((c) => c.currentCheckStep != CheckStep.Initial)
}
</script>

<template>
  <div v-if="areChecksInProgress()">
    <h1>In progress</h1>

    <div style="display: flex; flex-wrap: wrap; width: 100%; flex-direction: row; gap:1%;">
      <div
        v-for="offlineMetadata in checksStore.offlineChecksMetadata
          .filter((x) => x.currentCheckStep != CheckStep.Initial && !x.check.isCompleted)
          .sort((a: any, b: any) => (a.due > b.due ? 1 : -1))"
        :key="offlineMetadata.guid"
        class="card-container"
      >
        <InProgressCardComponent
          :check-offline-metadata="offlineMetadata"
          @onIssueReportSent="onIssueReportSent"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.today-panel {
  margin-top: 10px;
}
.card-container{
  width: 100%;
}

@media (min-width: 1024px) {
  .card-container {
    width: 49%;
    min-width: 49%;
    max-width: 49%;
  }
}
@media (min-width: 1600px) {
  .card-container {
    width: 32%;
    min-width: 32%;
    max-width:32%;
  }
}
</style>
