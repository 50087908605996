import { ApiClient } from '@/apiClient'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import type { CompletedCheck } from '@/interfaces/CompletedCheck'
import type { SaveCompletedCheckResponse } from '@/interfaces/SaveCompletedCheckResponse'
import { ElNotification } from 'element-plus'

export class ChecksService {
  apiClient = new ApiClient()

  async getTodaysChecks(): Promise<TodaysCheck[]> {
    let todaysChecks: TodaysCheck[] = []

    await this.apiClient.getTodaysChecks().then((response: any) => {
      if (response) {
        todaysChecks = response.data as TodaysCheck[]
      } else {
        ElNotification({
          title: 'Error',
          message: 'Failed to get today\'s checks',
          type: 'error'
        })
      }
    })

    return todaysChecks
  }

  async saveCompletedCheck(completedCheck: CompletedCheck): Promise<SaveCompletedCheckResponse> {
    const result: SaveCompletedCheckResponse = {
      success: false
    }

    await this.apiClient.saveCheck(completedCheck).then((response: any) => {
      result.success = response.status == 201
      result.errors = response.data?.errors
    })

    return result
  }
}
