import { ApiClient } from '@/apiClient'
import type { Location } from '@/interfaces/Location'

export class LocationService {
  apiClient = new ApiClient()

  async getLocations(): Promise<Location[]> {
    let locations: Location[] = []
    await this.apiClient.getLocations().then((response: any) => {
      locations = response.data;
    })
    return locations;
  }

  async setLocation(id: string): Promise<void> {
    const response : any = await this.apiClient.setLocation(id);
    localStorage.setItem('access_token', response.data.token)
  }
}
