<script lang="ts" setup>
import { computed, ref, watch, onBeforeMount } from 'vue'
import { InputType } from '@/enums/InputType';
import { useChecksStore } from '@/stores/checksStore';
import { storeToRefs } from 'pinia';
const emit = defineEmits(["taskClicked"])
const touched = ref('')
const checksStore = useChecksStore()
const { currentCheckMetadata } = storeToRefs(checksStore)
const list = ref(currentCheckMetadata.value!.check.checkTasks.filter(x => x.inputType != InputType.Timestamp));
watch(currentCheckMetadata, (value) => {
  list.value = currentCheckMetadata.value!.check.checkTasks.filter(x => x.inputType != InputType.Timestamp);
})
const isTaskFinished = (taskId: string): boolean => {
  const task = currentCheckMetadata.value!.check.checkTasks.find((t) => t.id == taskId)
  if (!task) {
    return false
  }
  if (task.inputType == InputType.Signature) return false;
  if (task.value == undefined) return false;
  return true
}
const isTaskNext = (taskId: string): boolean => {
  const tasks = currentCheckMetadata.value!.check.checkTasks.filter((t) => t.isCompleted)
  if (!tasks) return false
  const taskOrderItem = Math.max(...tasks.map(x => x.order))
  const currentTask = currentCheckMetadata.value!.check.checkTasks.find((t) => t.id == taskId);
  if (!currentTask) return false;
  if (currentTask.order == taskOrderItem+1) return true;
  return false;  
}
const onTaskClicked = (id:string) => {
  touched.value = id
  emit("taskClicked", id);
}
onBeforeMount(() => {
  list.value = currentCheckMetadata.value!.check.checkTasks.filter(x => x.inputType != InputType.Timestamp);
})
</script>

<template>
  <div style="width: auto; margin-bottom: 50px">
    <div style="margin-bottom: 10px">
      <el-text size="large" tag="b">Tasks</el-text>
    </div>
    <div v-for="task in list">
      <div style="display: flex; flex-direction: column; gap: 0">
        <div
          style="
            border-radius: 5px;
            margin-top: 8px;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 5px;
          "
          :class="[
            isTaskFinished(task.id) ? 'task-finished' : 'task-unfinished', 
            isTaskNext(task.id) ? 'task-next' : '', 
            touched == task.id ? 'touched' : ''
          ]"
          @click="onTaskClicked(task.id)"
        >
          <div
            style="
              display: flex;
              justify-content: start;
              vertical-align: center;
              align-items: center;
            "
          >
            <i v-if="isTaskFinished(task.id)" class="fa-solid fa-check check-icon"></i>
            <i v-if="isTaskNext(task.id)" class="fa-solid fa-arrow-right check-icon"></i>
            <el-text line-clamp="1">&nbsp;&nbsp;{{ task.label }}</el-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.task-unfinished,
.task-finished,
.task-next {
  font-size: 1.3em;
  padding: 10px;
}
.task-finished{
  background-color: #289548;
}
.task-next{
  background-color: #f3a127!important;
}
.task-unfinished{
  background: #ececec
}
.touched {
  background-color: #aaa!important;
}
.task-finished span,
.task-next span{
  color: #efefef;
}
.task-unfinished span,
.task-finished span {
  text-align: left;
}
.check-icon{
  margin-left: 5px;
}
</style>