<script lang="ts" setup>
    import { ref } from 'vue';

    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const error = ref<boolean>(false);
    const text = ref<string>(props.modelValue?.toString() || "");
    const emit = defineEmits(["onValueChange"]);

    const onValueChange = () => {
        if (text.value == "") {
            error.value = true
        } else {
            emit('onValueChange', text.value, 'ok')
        }
    }
</script>

<template>
    <div class="text-container">
        <el-input
            v-model="text"
            style="font-size: 2em"
            @keyup="onValueChange"
        />
        <div v-if="error" style="color: red">Please enter a value</div>
    </div>
</template>

<style scoped>
    .text-container{
        width:550px;
        max-width:80%;
        margin: 0 auto;
        text-align: center;
    }
</style>