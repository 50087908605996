import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { CheckOfflineMetadata } from '@/offlineDatabase'

export const useChecksStore = defineStore('checksStore', () => {
  const offlineChecksMetadata = ref<CheckOfflineMetadata[]>([])
  const currentCheckMetadata = ref<CheckOfflineMetadata>()

  const setChecks = (checks: CheckOfflineMetadata[]) => {
    offlineChecksMetadata.value = checks
  }

  const setCurrentCheck = (check: CheckOfflineMetadata): void => {
    currentCheckMetadata.value = check
  }

  const getCurrentCheckTasks = (): CheckOfflineMetadata[] => {
    return offlineChecksMetadata.value
  }

  const isCurrentCheckOverdue = (): boolean => {
    if (!currentCheckMetadata.value) {
      return false
    }

    const currentDate = new Date()

    const timeParts = currentCheckMetadata.value.check.due.split(':')
    const hours = parseInt(timeParts[0])
    const minutes = parseInt(timeParts[1])

    if (hours == 0 && minutes == 0) {
      return false
    }

    currentDate.setHours(hours)
    currentDate.setMinutes(minutes)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    return currentDate < new Date()
  }

  const updateCurrentCheckStateIsReportSent = (isReportSent: boolean) => {
    if (!currentCheckMetadata.value) {
      return
    }

    //currentCheckMetadata.value.check.issueReportSent = isReportSent
  }

  return {
    offlineChecksMetadata,
    currentCheckMetadata,
    setChecks,
    setCurrentCheck,
    getCurrentCheckTasks,
    isCurrentCheckOverdue,
    updateCurrentCheckStateIsReportSent
  }
})
