import Dexie, { type EntityTable } from 'dexie'
import type { CheckStep } from '@/enums/CheckStep'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import VueJwtDecode from 'vue-jwt-decode'

interface DeletionPolicy {
  excludeFromDeletion: boolean
  addedOn: Date
}

interface Id {
  id?: string
}

export interface CheckOfflineMetadata extends DeletionPolicy, Id {
  guid: string
  checkId: string
  check: TodaysCheck
  currentCheckStep: CheckStep
}


let lid = {lid: ""};
let isAuthenticated = localStorage.getItem('access_token') != null;
if (isAuthenticated) {
  var authToken = VueJwtDecode.decode(localStorage.getItem('access_token'));
  lid = authToken.lid;
}
let currentDatabase = "default";
let offlineDatabase:any = new Dexie(currentDatabase) as Dexie & {
  checkOfflineMetadata: EntityTable<CheckOfflineMetadata, 'id'>,
}

const loadDatabase = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  currentDatabase = 'checkMateDatabase' + user.id + lid;
  offlineDatabase = new Dexie(currentDatabase) as Dexie & {
    checkOfflineMetadata: EntityTable<CheckOfflineMetadata, 'id'>
  }
  offlineDatabase.version(1).stores({
    checkOfflineMetadata: '++id, guid, checkId, check, currentCheckStep, addedOn'
  })
}
if (isAuthenticated) loadDatabase();

const removeOldRecordsFromOfflineDatabase = async () => {
  const date = new Date()
  const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const allRecords = await offlineDatabase.checkOfflineMetadata.toArray()
  allRecords.forEach((record:CheckOfflineMetadata) => {
    if (record.addedOn < currentDate && !record.excludeFromDeletion) {
      offlineDatabase.checkOfflineMetadata.delete(record.id!)
    }
  })
}

const clearDatabase = async () => {
  await offlineDatabase.checkOfflineMetadata.clear();
}

export { offlineDatabase, removeOldRecordsFromOfflineDatabase, loadDatabase, clearDatabase }
