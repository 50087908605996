import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import DashboardView from '@/components/dashboard/DashboardView.vue'
import CheckView from '@/views/CheckView.vue'
import MyAccountView from '@/views/MyAccountView.vue'
import { useChecksStore } from '@/stores/checksStore'
import { storeToRefs } from 'pinia'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView
    },
    {
      path: '/account',
      name: 'account',
      component: HomeView,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          component: DashboardView,
          meta: { requiresAuth: true }
        },
        {
          path: 'check',
          component: CheckView,
          meta: { requiresAuth: true },
          beforeEnter: async (to, from) => {
            const checksStore = useChecksStore()
            const { currentCheckMetadata } = storeToRefs(checksStore)

            if (currentCheckMetadata.value == undefined) {
              await router.push('/account/dashboard')
              return false
            }
          }
        },
        {
          path: 'my-account',
          component: MyAccountView,
          meta: { requiresAuth: true }
        },
        {
          path: "/:catchAll(.*)",
          component: HomeView,
        },
      ]
    },
    {
      path: "/:catchAll(.*)",
      component: HomeView,
    },
  ]
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('access_token')

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
