<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useLocationStore } from '@/stores/locationStore'
import VueJwtDecode from "vue-jwt-decode"

const locationStore = useLocationStore()
const locations = ref([] as any[]);
const currentLocation = ref("");

onMounted(async () => {
  await locationStore.getLocations()
  locations.value = locationStore.locations.map((s:any) => ({text: s.name, value: s.id}));

  if (localStorage.getItem('access_token') != null) {
    const token = localStorage.getItem('access_token')!
    const userdata = VueJwtDecode.decode(token);
    currentLocation.value = userdata.lid
  }

})


const setLocation = async (e:any) => {
  await locationStore.setLocation(e)
  window.location.reload()
}
</script>

<template>
    <div v-if="locationStore.locations?.length > 1">
        <el-select size="large" @change="setLocation" v-model="currentLocation">
            <el-option
                v-for="item in locations"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </div>
</template>