<script lang="ts" setup>
import CheckWizardTabComponent from '@/components/CheckWizardTabComponent.vue'
import { useChecksStore } from '@/stores/checksStore'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import type { CheckTask } from '@/interfaces/CheckTask'
import ReportIssueModalComponent from '@/components/ReportIssueModalComponent.vue'
import { ChecksService } from '@/services/ChecksService'
import type { CompletedCheck } from '@/interfaces/CompletedCheck'
import type { CheckAnswer } from '@/interfaces/CheckAnswer'
import type { SaveCompletedCheckResponse } from '@/interfaces/SaveCompletedCheckResponse'
import { ElNotification } from 'element-plus'
import { offlineDatabase } from '@/offlineDatabase'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { useRouter } from 'vue-router'
import { CheckStep } from '@/enums/CheckStep'
import { InputType } from '@/enums/InputType'
import CorrectiveActionsModalComponent from '@/components/CorrectiveActionsModal/CorrectiveActionsModal.vue'
import type { CheckWizardTabComponentRef, CorrectiveActionsModalRef } from '@/components/CorrectiveActionsModal/CorrectiveActionsModalRef'
import type { Issue } from '@/interfaces/Issue'
import { AppOrWorker } from '@/utils'

const emit = defineEmits(["backToSummary"])
const props = defineProps<{
  startstepid: string | undefined
}>()
const router = useRouter()
const checksStore = useChecksStore()
const { currentCheckMetadata } = storeToRefs(checksStore)
const offlineDatabaseService = OfflineDatabaseService()
const checkOfflineService = CheckOfflineService()
const checkTasks = ref(currentCheckMetadata.value!.check.checkTasks.sort((a, b) => (a.order > b.order ? 1 : -1)))
const checkName = ref(currentCheckMetadata.value!.check.title)
const windowWidth = window.innerWidth;
const isReportIssueModalComponentOpen = ref(false)
const reportIssueModalRef = ref(null as any)
const activeIndex = ref(props.startstepid == undefined ? 0 : currentCheckMetadata.value!.check.checkTasks.findIndex(x => x.id == props.startstepid));
const activeTask = ref(currentCheckMetadata.value!.check.checkTasks[activeIndex.value]);
const confirmCancelDialogVisible = ref(false)
const correctiveActionsModalComponentRef = ref<CorrectiveActionsModalRef>()
const checkWizardTabComponentRef = ref([] as CheckWizardTabComponentRef[])
const loading = ref(false);
const worker = inject("worker") as Worker;
const x = computed(() => {
  return -((window.innerWidth * activeIndex.value)+30) + "px"
})
const validationOutcome = ref<'under' | 'over' | 'invalid' | 'ok'>('ok')


onMounted(() => {
  validationOutcome.value = checkWizardTabComponentRef.value[activeIndex.value].getValue();
})
const checkValidation = (validationResult: 'under' | 'over' | 'invalid' | 'ok') : boolean => {
  if (validationResult === 'ok') return true; 
  if (issueAlreadyLogged()) return true;
  return false;
}
const issueAlreadyLogged = () => {
  if (checksStore.currentCheckMetadata != undefined && checksStore.currentCheckMetadata?.check.issues == undefined) 
    checksStore.currentCheckMetadata.check.issues = [];
  return checksStore.currentCheckMetadata?.check.issues.find(x => x.taskId === activeTask.value.id) != null
}
const validateStep = (value:any, validationResult:'under' | 'over' | 'invalid' | 'ok') => {
  //  Sepcial case for signature
  if (activeTask.value.inputType == InputType.Signature) {
    if (validationResult == 'invalid' || value == undefined || value == "|") {
      ElNotification({
        title: 'Warning',
        message: 'Signature & name is required',
        type: 'warning',
        customClass: 'notification'
      })
      return
    }
  }
  //  Textareas and dropdowns cannot conitnue without a value
  if (activeTask.value.inputType == InputType.Text ||
      activeTask.value.inputType == InputType.TextArea ||
      activeTask.value.inputType == InputType.Select) {
    if (value == undefined || value == "") {
      ElNotification({
        title: 'Warning',
        message: 'Please enter a value',
        type: 'warning',
        customClass: 'notification'
      })
      return
    }
  }

  //  Normal circumstances
  if (checkValidation(validationResult)) {
    return true;
  } else {
    return false;
  }
}
const openReportIssueModal = (task: CheckTask) => {
  if (reportIssueModalRef.value) {
    const checkId: string = task.checkId
    const taskId: string = task.id
    reportIssueModalRef.value.setCheckId(checkId)
    reportIssueModalRef.value.setTaskId(taskId)
  }

  isReportIssueModalComponentOpen.value = true
}
const onNextClick = async () => {
  validationOutcome.value = checkWizardTabComponentRef.value[activeIndex.value].getValue();
  const checkTask = checkTasks.value.at(activeIndex.value)!
  await checkOfflineService.updateCheckTaskProgress(
    currentCheckMetadata.value!.guid!,
    checkTask,
    checkTask.value,
    CheckStep.TasksWizard
  )

  if (activeTask.value.value == undefined || activeTask.value.value == "" || activeTask.value.value == "|") {
    ElNotification({
      title: 'Warning',
      message: 'Please enter a value',
      type: 'warning',
      customClass: 'notification'
    })
    return;
  }


  if (!validateStep(activeTask.value.value, validationOutcome.value)) {
    openCorrectiveActionsModal();
  } else {
    activeIndex.value += 1;
    checkWizardTabComponentRef.value[activeIndex.value].begin();
    activeTask.value = checkTasks.value.at(activeIndex.value)!
    checkWizardTabComponentRef.value[activeIndex.value].getValue();
  }
}
const onPreviousTabClick = async () => {
  activeIndex.value -= 1; 
  activeTask.value = checkTasks.value.at(activeIndex.value)!
  if(activeIndex.value == -1) {
    emit("backToSummary")
  }
}
const onValueChange = async (checkTask: CheckTask, newValue: any, outcome: 'under' | 'over' | 'invalid' | 'ok') => {
  if (newValue == undefined) return;
  checkTask.value = newValue
  if (checkTask.id == activeTask.value.id) {
    activeTask.value.value = newValue;
    validationOutcome.value = outcome;
  }
  await checkOfflineService.updateCheckTaskProgress(
    currentCheckMetadata.value!.guid,
    checkTask,
    newValue,
    CheckStep.TasksWizard
  )
}
const onFinish = async () => {
  if (activeTask.value.inputType == InputType.Signature && /([^\|]+)\|([^\|]+)/.test(activeTask.value.value as string) == false) {
    ElNotification({
      title: 'Warning',
      message: 'Signature & name is required',
      type: 'warning',
      customClass: 'notification'
    });
    return;
  }

  if (activeTask.value.value == undefined || activeTask.value.value == "" || activeTask.value.value == "|") {
    ElNotification({
      title: 'Warning',
      message: 'Please enter a value',
      type: 'warning',
      customClass: 'notification'
    })
    return;
  }

  const checksService = new ChecksService()
  const refreshedCheck = await offlineDatabaseService.getCheckByGuid(currentCheckMetadata.value!.guid);
  if (currentCheckMetadata.value!.check.checkTasks.find(x => x.value == undefined || x.value == "|") != undefined) {
    ElNotification({
      title: 'Error',
      message: 'There are still tests that require a value',
      type: 'error',
      customClass: 'notification'
    })
    currentCheckMetadata.value!.currentCheckStep = CheckStep.TaskContents;
    emit("backToSummary")
    return;
  }

  loading.value = true;
  const completedCheck: CompletedCheck = {
    checkId: currentCheckMetadata.value!.check.id,
    answers: currentCheckMetadata.value!.check.checkTasks.map(
      (task: CheckTask) =>
        ({
          taskId: task.id,
          value: task.value ? (task.value as string) : ''
        }) as CheckAnswer
    ) as CheckAnswer[],
    issues: refreshedCheck!.check.issues,
  }
  
  let result: SaveCompletedCheckResponse = await checksService.saveCompletedCheck(completedCheck)

  if (result.success) {
    ElNotification({
      title: 'Success',
      message: 'Check has been saved',
      type: 'success',
      customClass: 'notification'
    })
    
    if (currentCheckMetadata.value!.check.canBeFinishedMultipleTimes) {
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).delete();
    } else {
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).modify({ 
        "check.isCompleted": true, 
        "currentCheckStep": CheckStep.Initial 
      });
    }

    AppOrWorker(worker).postMessage({ type: 'clearallforcheck', checkId: currentCheckMetadata.value!.check.id });

    await router.push('/account/dashboard')
    return
  }
  ElNotification({
    title: 'Error',
    message: 'Check could not been saved',
    type: 'error',
    customClass: 'notification'
  })
}
const openCorrectiveActionsModal = () => {
  if (!correctiveActionsModalComponentRef.value) return;

  if (!issueAlreadyLogged()){
    correctiveActionsModalComponentRef.value!.openModal(
      activeTask.value.checkId,
      activeTask.value.id,
      activeTask.value.label,
      activeTask.value.correctiveActions,
      validationOutcome.value
    )
  }
}
const onCorrectiveActionsConfirmed = (issue: Issue) => {
  checksStore.currentCheckMetadata?.check.issues.push(issue)
  if (activeIndex.value != checkTasks.value.length) {
    onNextClick();
  } else {
    onFinish();
  }
}
const onCancelCheckClicked = (t:boolean) => {
  if (t)
    checkOfflineService.cancelCheck(currentCheckMetadata!.value!)
  router.push('/account/dashboard')
}
</script>

<template>
    <div  class="slider" style="" :style="{width: (windowWidth*checkTasks.length) + 'px', overflow:'hidden', transform: 'translateX(' + x +')' }">
      <template v-for="(check, index) in checkTasks">
        <div 
          style="height: 100%; margin-top: 1em;" :style="{width: windowWidth + 'px'}"
        >
          <CheckWizardTabComponent
            ref="checkWizardTabComponentRef"
            :CheckName="checkName"
            :CheckTask="check"
            :CheckTaskOfflineValue="check.value"
            :PageNumber="index + 1"
            :ShowNextTabButton="checkTasks.length > index + 1"
            :ShowPreviousTabButton="index >= 1"
            :TotalPageNumber="checkTasks.length"
            @onNextClick="onNextClick()"
            @onValueChange="(value, outcome) => onValueChange(check, value, outcome)"
          />
        </div>
      </template>
    </div>
    
    <div style="margin-top: auto;" class="buttonSection">
      <div style="display: flex; padding: 0px">     
        <el-button
          class="bottom-buttons previous-button"
          @click="onPreviousTabClick"
          >Previous
        </el-button>
        
        <el-button class="bottom-buttons report-issue-button" @click="openReportIssueModal(activeTask)">
          <i class="fa fa-triangle-exclamation"></i>
        </el-button>
        
        <el-button class="bottom-buttons cancel-button" 
          @click="confirmCancelDialogVisible = true"
          >
          <i class="fa fa-x"></i>
        </el-button>

        <el-button
          v-if="activeIndex < checkTasks.length - 1"
          class="bottom-buttons next-button"
          @click="onNextClick"
          >Next
        </el-button>

        <el-button
          :loading="loading"
          v-if="activeIndex >= checkTasks.length - 1"
          class="bottom-buttons next-button"
          @click="onFinish"
          >Finish
        </el-button>
      </div>
    </div>

  <ReportIssueModalComponent
    ref="reportIssueModalRef"
    :is-open="isReportIssueModalComponentOpen"
    @close="isReportIssueModalComponentOpen = false"
  />

  <CorrectiveActionsModalComponent
    ref="correctiveActionsModalComponentRef"
    @sent="onCorrectiveActionsConfirmed"
  />

  <el-dialog v-model="confirmCancelDialogVisible" title="Leave check" width="500">
    <span>Do you want to Save and leave for later or cancel this check?</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="confirmCancelDialogVisible = false">Back</el-button>
        <el-button type="primary" @click="onCancelCheckClicked(false)">Save for later</el-button>
        <el-button type="danger" @click="onCancelCheckClicked(true)">Cancel and delete</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>
.n-config-provider{height: 100%}


.bottom-buttons {
  border-radius: 0;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.report-issue-button {
  --el-button-bg-color: #dfae45;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #d38f36;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.cancel-button {
  --el-button-bg-color: #e1341e;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #e1341e;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.next-button {
  --el-button-bg-color: #79b342;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #79b342;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.previous-button {
  --el-button-bg-color: #009de0;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #009de0;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}

.slider{
  display: flex;
  transition: all .3s cubic-bezier(0.645,0.045,0.355,1);
}

</style>