<script lang="ts" setup>
import { ref } from 'vue'
import { useChecksStore } from '@/stores/checksStore'
import TasksWizard from '@/components/TasksWizard.vue'
import TaskContents from '@/components/TaskContents.vue'
import { CheckStep } from '@/enums/CheckStep'
import FillLateReport from '@/components/FillLateReport.vue'
import { FadeInOut } from 'vue3-transitions'
import ReadMore from '@/components/ReadMore.vue'

const checksStore = useChecksStore()
const currentCheckStep = ref<CheckStep>(checksStore.currentCheckMetadata!.currentCheckStep == CheckStep.FillLateReport ? CheckStep.FillLateReport : CheckStep.TaskContents)
const startStepId = ref<string | undefined>(undefined)
const goToStep = (id: string) => {
  startStepId.value = id;
  setCurrentStep(CheckStep.TasksWizard)
}

const setCurrentStep = (checkStep: CheckStep) => {
  currentCheckStep.value = checkStep
}
</script>

<template>
  <div v-if="checksStore.currentCheckMetadata" class="container" style="overflow:hidden">
    <div
      :style="
        'background-color: ' +
        checksStore.currentCheckMetadata.check.icon.colour +
        '; border-radius: 6px; padding-left: 10px; padding-right: 10px'
      ">
      <i
        :class="checksStore.currentCheckMetadata.check.icon.icon"
        style="font-size: 20px; margin-right: 10px"
      ></i>
      <el-text class="page-title-text">{{ checksStore.currentCheckMetadata.check.title }}</el-text>
    </div>

    <div class="page-description-text">
      <ReadMore :text="checksStore.currentCheckMetadata.check.description" :maxChars="200" moreStr="read more" lessStr="read less" />
    </div>

    <FadeInOut entry="center" exit="center" group :duration="250" mode="out-in" tag="div">
      <div v-if="currentCheckStep == CheckStep.FillLateReport" :key="CheckStep.FillLateReport">
        <FillLateReport @onStepFinished="setCurrentStep(CheckStep.TaskContents)" />
      </div>

      <div v-if="currentCheckStep == CheckStep.TaskContents" :key="CheckStep.TaskContents">
        <TaskContents @onStepFinished="goToStep" />
      </div>

      <div v-if="currentCheckStep == CheckStep.TasksWizard">
        <TasksWizard :startstepid="startStepId" @backToSummary="currentCheckStep = CheckStep.TaskContents" :key="CheckStep.TasksWizard" />
      </div>
    </FadeInOut>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  /*height: 100%*/
  padding:10px;
  border-radius: 8px;
  background:#fff;
  padding-bottom:  100px;
}

.page-title-text {
  width: 100%;
  font-size: 24px;
  color: #151740;
}

.page-description-text {
  margin-top: 14px;
  text-align: left;
  display: block;
  align-self: auto;
  font-size: .9em;
}

.contents {
  background: #fff;
  padding: 10px;
  text-align: center;
}
</style>
