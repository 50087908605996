<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { UserService } from "@/services/UserService";
import { ElNotification } from "element-plus";

const router = useRouter();
const errors = ref("");
const oldpassword = ref("");
const password = ref("");
const confirmpassword = ref("");
const loading = ref(false);
const passwordComplexity = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const changePassword = async () => {
    if (!passwordComplexity.test(password.value)) {
        errors.value = "Password must contain at least 8 characters, an uppercase, lowercase, numberal and special character";
        return;
    }
    if (confirmpassword.value !== password.value) {
        errors.value = "Passwords do not match";
        return;
    }
    loading.value = true;
    const userService = new UserService();
    userService.resetPassword(oldpassword.value, password.value).then(async () => {
        await router.push('/account/dashboard')
    }).catch((error) => {
        console.log(error);
        if (error.response.data === "Incorrect password.") {
            errors.value = "Old password is incorrect";
        } else if(error.response.data === "Not found") {
            errors.value = "User doesnt exist";
        } else {
            errors.value = error.response.data;
        }
        
        loading.value =false;
    });
}
</script>

<template>
    <div>
        <div>
            <h2>Change your Password</h2>
            <div style="min-height: 2.5em; color: red;">
                {{ errors }}
            </div>
            <div style="margin-bottom: 1.5em;">
                <el-input
                    v-model="oldpassword"
                    clearable
                    placeholder="Old Password"
                    resize="none"
                    size="large"
                    style="width: 100%"
                    type="password"
                    show-password
                />
            </div>

            <div style="margin-bottom: 1.5em;">
                <el-input
                    v-model="password"
                    clearable
                    placeholder="Old Password"
                    resize="none"
                    size="large"
                    style="width: 100%"
                    type="password"
                    show-password
                />
            </div>

            <div style="margin-bottom: 1.5em;">
                <el-input
                    v-model="confirmpassword"
                    clearable
                    placeholder="Confirm Password"
                    resize="none"
                    size="large"
                    style="width: 100%"
                    type="password"
                    show-password
                />
            </div>


            <div class="mt-2">
                <el-button class="login-button" size="large" @click="changePassword" :loading="loading">Change Password</el-button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login-button {
  width: 100%;
  background-color: #289548;
  border-color: #289548;
  color: #fff;
  font-size: 1.5em;
}
</style>