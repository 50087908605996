<script setup lang="ts">
import AudioAlert from './components/AudioAlert.vue';
import { postMessage } from './utils';

window.addEventListener("load", function () {
  //postMessage({ type: 'apptest' });
});

window.onmessage = function (e) {
  try{
    const data = JSON.parse(e.data);
    if (data.type === 'apptest') {
      if (data.data != undefined) {
        console.log("App");
      }
    }
  } catch (ex) {
    console.log(ex, e);
  }
};
</script>

<template>
  <RouterView>
  </RouterView>
  <audio-alert />
</template>

<style scoped>
#background-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
