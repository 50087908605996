import { ChecksService } from '@/services/ChecksService'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { CheckStep } from '@/enums/CheckStep'
import { type CheckOfflineMetadata, offlineDatabase } from '@/offlineDatabase'
import { AppOrWorker } from '@/utils'

export const DatabaseSynchronizationService = (w: Worker) => {
  const checksService = new ChecksService()
  const checkOfflineService = CheckOfflineService()
  const offlineDatabaseService = OfflineDatabaseService()
  const worker = w;
  const synchronize = async (): Promise<void> => {
    const remoteChecks = await checksService.getTodaysChecks()
    const localChecks = await offlineDatabaseService.getAllChecks()

    remoteChecks.map(async function (check: TodaysCheck) {
      const offlineCheck = await checkOfflineService.getCheckById(check.id)

      if (check.due != "" && check.category == "Today" && !checkOfflineService.isOverdue(check)) {
        AppOrWorker(worker).postMessage({
          type: "alert",
          minutes: 0,
          start: new Date(),
          message: check.title,
          checkId: check.id,
          due: checkOfflineService.getDueAsDate(check)
        })
      }

      if (offlineCheck == undefined) {
        check.issues = [];
        await checkOfflineService.addCheck(check, CheckStep.Initial)
      } if (offlineCheck != undefined && offlineCheck.check.version < check.version  && offlineCheck?.currentCheckStep == CheckStep.Initial) {
        await checkOfflineService.updateCheck(offlineCheck, check)
      }
    })

    localChecks.map(async function (checkOfflineMetadata: CheckOfflineMetadata) {
      const onlineCheck = remoteChecks.find((c) => c.id == checkOfflineMetadata.check.id)

      if (!onlineCheck && checkOfflineMetadata?.currentCheckStep == CheckStep.Initial) {
        offlineDatabase.checkOfflineMetadata.delete(checkOfflineMetadata.id!)
      }
    })

    await offlineDatabase.checkOfflineMetadata.toArray()

    return
  }

  return { synchronize }
}
