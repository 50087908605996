import type { LoginCredentials } from '@/interfaces/LoginCredentials'
import { ApiClient } from '@/apiClient'
import type { AuthenticationResult } from '@/interfaces/AuthenticationResult'
import axios from 'axios'
import { loadDatabase } from '@/offlineDatabase'
import * as Sentry from "@sentry/vue";

export class AuthenticationService {
  async login(loginCredentials: LoginCredentials): Promise<AuthenticationResult> {
    const result: AuthenticationResult = {
      success: false,
      error: '',
      token: "",
      option: 0
    }

    const apiClient = new ApiClient()

    await apiClient
      .login(loginCredentials)
      .then((response: any) => {
        if (response.data.token) {
          localStorage.setItem('access_token', response.data.token)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          Sentry.setUser({ id: response.data.user.id, email: response.data.user.email });
          loadDatabase();
          axios.defaults.headers.common = { Authorization: `bearer ${response.data.token}` }
          result.success = true
          result.token = response.data.token ;
          result.option = response.data.user.option;
          return result
        }
      })
      .catch((error) => {
        result.error = error.response.data
        return result
      })

    return result
  }

  logout() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
  }
}
