import type { CheckTask } from '@/interfaces/CheckTask'
import { type CheckOfflineMetadata, offlineDatabase } from '@/offlineDatabase'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import { CheckStep } from '@/enums/CheckStep'
import { v4 as uuidv4 } from 'uuid'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import { useChecksStore } from '@/stores/checksStore'
import { isProxy, toRaw } from 'vue'
import type { Issue } from '@/interfaces/Issue'
import { get, update } from 'lodash'

export const CheckOfflineService = () => {
  const offlineDatabaseService = OfflineDatabaseService()
  const checksStore = useChecksStore()

  const getCheckById = async (
    checkId: string | undefined
  ): Promise<CheckOfflineMetadata | undefined> => {
    if (!checkId) {
      return undefined
    }

    return await offlineDatabaseService.getCheckByCheckId(checkId)
  }

  const setCheckIsReportSent = async (checkId: string, isReportSent: boolean): Promise<void> => {
    const offlineCheck = await getCheckById(checkId)

    if (!offlineCheck) {
      return
    }

    await offlineDatabase.checkOfflineMetadata.update(offlineCheck.id!, {
      check: offlineCheck.check
    })
  }

  const isCheckInProgress = async (checkId: string | undefined): Promise<boolean> => {
    if (!checkId) {
      return false
    }

    const offlineCheck = await offlineDatabaseService.getCheckByCheckId(checkId)

    if (!offlineCheck) {
      return false
    }

    return offlineCheck.currentCheckStep != CheckStep.Initial
  }

  const addOrUpdateCheckTaskProgress = async (
    checkTask: CheckTask,
    check: CheckOfflineMetadata,
    value: string | number,
    currentCheckStep: CheckStep
  ) => {
    if (!value) {
      return
    }

    const offlineCheck = await offlineDatabaseService.getLastCompletedTaskIdByCheckOfflineGuid(
      check.id
    )

    if (!offlineCheck) {
      await addCheckOfflineMetadata(check, CheckStep.TaskContents)

      await addCheckTask(checkTask, value, currentCheckStep)
      return
    }

    await updateCheckTaskProgress(check.id!, checkTask, value, currentCheckStep)
  }

  const getCheckCurrentStep = async (
    checkId: string | undefined
  ): Promise<CheckStep | undefined> => {
    if (!checkId) {
      return undefined
    }

    const offlineCheck = await offlineDatabaseService.getCheckByCheckId(checkId)

    if (!offlineCheck) {
      return undefined
    }

    return offlineCheck.currentCheckStep
  }

  const updateCheckCurrentStep = async (
    checkOfflineMetadata: CheckOfflineMetadata,
    currentCheckStep: CheckStep
  ) => {
    if (!checkOfflineMetadata) {
      return
    }
    const offlineCheck = await offlineDatabaseService.getCheckByGuid(checkOfflineMetadata.guid)
    if (!offlineCheck) {
      await addCheckOfflineMetadata(checkOfflineMetadata, currentCheckStep)
      return
    }
    await offlineDatabase.checkOfflineMetadata.update(offlineCheck.id!, {
      currentCheckStep: currentCheckStep
    })
  }

  const addIssue = async(issue: Issue, guid: string) => {
    const offlineCheck = await offlineDatabaseService.getCheckByGuid(guid)
    if (offlineCheck == undefined) return;
    if (offlineCheck.check.issues == undefined || offlineCheck.check.issues == null) offlineCheck.check.issues = [];
    offlineCheck.check.issues.push(issue)
    await offlineDatabase.checkOfflineMetadata.update(offlineCheck.id!, {check: offlineCheck.check});
  }

  const addCheck = async (check: TodaysCheck, currentCheckStep: CheckStep) => {
    const checks = await offlineDatabaseService.getAllChecks()
    const foundCheck = checks.find((c) => c.checkId == check.id)

    if (foundCheck) {
      return
    }

    check.checkTasks.forEach(function (checkTask: CheckTask) {
      //checkTask.isCompleted = false
    });
    check.issues = []
    //check.isCompleted = false

    const newCheck: CheckOfflineMetadata = {
      guid: uuidv4(),
      checkId: check.id,
      check: check,
      addedOn: new Date(),
      excludeFromDeletion: false,
      currentCheckStep: currentCheckStep
    }

    offlineDatabase.checkOfflineMetadata.add(newCheck)
  }
  
  const updateCheck = async (currentCheck:  CheckOfflineMetadata, check: TodaysCheck) => {
    const chk = await offlineDatabaseService.getCheckByGuid(currentCheck.guid);
    if (chk == undefined) {
      return;
    }
    offlineDatabase.checkOfflineMetadata.update(chk.id, {check: check});
  }

  const addCheckOfflineMetadata = async (
    checkOfflineMetadata: CheckOfflineMetadata,
    currentCheckStep: CheckStep
  ) => {
    if (isProxy(checkOfflineMetadata.check)) {
      checkOfflineMetadata.check = toRaw(checkOfflineMetadata.check)
    }

    const checks = await offlineDatabaseService.getAllChecks()

/*    if (checkOfflineMetadata.check.category != 'Extra') {
      const foundCheck = checks.find((c) => c.checkId == checkOfflineMetadata.check.id)
      if (foundCheck) {
        return
      }
    }*/

    checkOfflineMetadata.check.checkTasks.forEach(function (checkTask: CheckTask) {
      checkTask.isCompleted = false
    })

    const newCheck: CheckOfflineMetadata = {
      guid: checkOfflineMetadata.guid,
      checkId: checkOfflineMetadata.check.id,
      check: checkOfflineMetadata.check as TodaysCheck,
      addedOn: new Date(),
      excludeFromDeletion: false,
      currentCheckStep: currentCheckStep
    }

    offlineDatabase.checkOfflineMetadata.add(newCheck)

    offlineDatabase.checkOfflineMetadata.toArray()
  }

  const addCheckTask = async (
    checkTask: CheckTask,
    value: string | number,
    currentCheckStep: CheckStep
  ) => {
    const offlineCheck = await getCheckById(checkTask.checkId)

    if (!offlineCheck) {
      return
    }

    const task = offlineCheck.check.checkTasks.find((t) => t.id == checkTask.id)

    if (task) {
      task.value = value
      task.isCompleted = true
    }

    offlineCheck.currentCheckStep = currentCheckStep

    await offlineDatabase.checkOfflineMetadata.update(offlineCheck.id!, {
      check: offlineCheck.check
    })
  }

  const updateCheckTaskProgress = async (
    checkGuid: string,
    checkTask: CheckTask,
    value: string | number,
    currentCheckStep: CheckStep
  ) => {
    const offlineCheck = await offlineDatabaseService.getCheckByGuid(checkGuid)

    if (!offlineCheck) {
      return
    }

    const offlineCheckTask = offlineCheck.check.checkTasks.find((t) => t.id == checkTask.id)

    if (!offlineCheckTask) {
      return
    }

    offlineCheckTask.value = value
    offlineCheckTask.isCompleted = true

    await offlineDatabase.checkOfflineMetadata.update(offlineCheck.id!, {
      check: offlineCheck.check,
      currentCheckStep: currentCheckStep
    })
  }

  const startNormalCheck = async (checkOfflineMetadata: CheckOfflineMetadata) => {
    await updateCheckCurrentStep(
      checkOfflineMetadata,
      isOverdue(checkOfflineMetadata.check) ? CheckStep.FillLateReport : CheckStep.TasksWizard
    )

    checkOfflineMetadata.currentCheckStep = isOverdue(checkOfflineMetadata.check)
      ? CheckStep.FillLateReport
      : CheckStep.TaskContents

    checksStore.setCurrentCheck(checkOfflineMetadata)
    return
  }

  const startExtraCheck = async (checkOfflineMetadata: CheckOfflineMetadata) => {
    const newCheckOfflineMetadata: CheckOfflineMetadata = {
      guid: uuidv4(),
      checkId: checkOfflineMetadata.check.id,
      check: checkOfflineMetadata.check,
      currentCheckStep: isOverdue(checkOfflineMetadata.check) ? CheckStep.FillLateReport : CheckStep.TaskContents,
      addedOn: new Date(),
      excludeFromDeletion: false
    }

    await addCheckOfflineMetadata(newCheckOfflineMetadata, newCheckOfflineMetadata.currentCheckStep)
    offlineDatabase.checkOfflineMetadata.toArray()
    checksStore.setCurrentCheck(newCheckOfflineMetadata)
  }

  const getDueAsDate = (check: TodaysCheck): Date => {
    const currentDate = new Date()

    const timeParts = check.due.split(':')
    const hours = parseInt(timeParts[0])
    const minutes = parseInt(timeParts[1])

    if (hours == 0 && minutes == 0) {
      return currentDate;
    }

    currentDate.setHours(hours)
    currentDate.setMinutes(minutes)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)
    return currentDate;
  }

  const isOverdue = (check: TodaysCheck): boolean => {
    const currentDate = getDueAsDate(check)
    return currentDate < new Date()
  }

  const cancelCheck = async (checkOfflineMetadata: CheckOfflineMetadata): Promise<void> => {
    const check = await offlineDatabaseService.getCheckByGuid(checkOfflineMetadata.guid)
    if (!check) {
      return
    }
    offlineDatabaseService.deleteCheck(check!.id!)
  }

  return {
    addOrUpdateCheckTaskProgress,
    updateCheckCurrentStep,
    getCheckCurrentStep,
    getCheckById,
    addCheck,
    addCheckOfflineMetadata,
    isCheckInProgress,
    setCheckIsReportSent,
    startNormalCheck,
    startExtraCheck,
    updateCheckTaskProgress,
    cancelCheck,
    addIssue,
    updateCheck,
    getDueAsDate,
    isOverdue
  }
}
