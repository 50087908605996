import { ApiClient } from '@/apiClient'
import type { GenericApiResponse } from '@/interfaces/GenericApiResponse'


export class NotificationsService {
  apiClient = new ApiClient()
  async getNotifications(): Promise<Notification[]> {
    return this.apiClient
      .getNotifications()
      .then((response: any) => {
        if (response.success || response.status === 200) {
          return Promise.resolve(response.data);
        }
      })
    return [];
  }

  async setNotificationAsSeen(): Promise<GenericApiResponse> {
    const self = this

    const apiResponse: GenericApiResponse = {
      success: true
    }
    await this.apiClient.setNotificationsAsSeen()
    return apiResponse
  }
}
export const NotificationService = new NotificationsService()