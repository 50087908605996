<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue'
import { InputType, isInputTypeTemperature } from '@/enums/InputType'
import type { CheckTask } from '@/interfaces/CheckTask'
import { useSettingsStore } from '@/stores/settingsStore'
import MessageComponent from './tasks/MessageComponent.vue'
import SignatureComponent from './tasks/SignatureComponent.vue'
import TemperatureComponent from './tasks/TemperatureComponent.vue'
import TextareaComponent from './tasks/TextareaComponent.vue'
import TextComponent from './tasks/TextComponent.vue'
import CheckboxComponent from './tasks/CheckboxComponent.vue'
import CheckboxNoExpectedComponent from './tasks/CheckboxNoExpectedComponent.vue'
import DropdownComponent from './tasks/DropdownComponent.vue'
import { inject } from 'vue';
import ReadMore from './ReadMore.vue'
import { AppOrWorker } from '@/utils'

const worker: Worker = inject('worker')!;
const emit = defineEmits({
  onValueChange: (value: number | string | boolean | undefined | 'Yes' | 'No', outcome:'under' | 'over' | 'invalid' | 'ok') => value,
  onNextClick: () => true
})

let props = defineProps<{
  CheckName:string,
  CheckTask: CheckTask
  CheckTaskOfflineValue: number | string | boolean | undefined | 'Yes' | 'No'
  ShowNextTabButton: boolean
  ShowPreviousTabButton: boolean
  PageNumber: number
  TotalPageNumber: number
}>()

const settingsStore = useSettingsStore();
const value = ref<number | string | boolean | undefined | 'Yes' | 'No'>(undefined);
const max = ref<number | undefined>(undefined);
const min = ref<number | undefined>(undefined);
const options = ref<string[] | string | undefined>(undefined);
const validationResult = ref<'under' | 'over' | 'invalid' | 'ok'>("invalid");

onBeforeMount(() => {
  setAlarm()
  if (isInputTypeTemperature(props.CheckTask.inputType)) {
    const settings = settingsStore.getTemperatureSettings(props.CheckTask.inputType)
    value.value = 0.0;
    max.value = settings?.max;
    min.value = settings?.min;
  }
  if (props.CheckTaskOfflineValue) {
    value.value = props.CheckTaskOfflineValue
  }
  options.value = props.CheckTask.inputTypeValues;
});

const begin = () => {
  if (props.CheckTask.inputType === InputType.Timestamp || props.CheckTask.inputType === InputType.Message) {
    emit('onValueChange', new Date().toLocaleString(), 'ok');
    validationResult.value = 'ok';
    if (props.CheckTask.inputType === InputType.Timestamp) {
      emit('onNextClick');
      return;
    }
  }
}
const getValue = () => {
  emit('onValueChange', value.value, validationResult.value);
  return validationResult.value
}
const setAlarm = () => {
  if (props.CheckTask.alarmMinutes > 0)  {
    AppOrWorker(worker).postMessage({ 
      type: 'alarm',
      minutes: props.CheckTask.alarmMinutes,
      checkId: props.CheckTask.checkId,
      message: `${props.CheckName} is due`
    });
  }
}
const onValueChanged = (val: number | string | boolean | undefined | 'Yes' | 'No', outcome: 'under' | 'over' | 'invalid' | 'ok') => {
  validationResult.value = outcome;
  value.value = val;
  emit('onValueChange', val, outcome);
}
const skip = () => {
  emit('onValueChange', "Skipped", 'ok');
  emit("onNextClick");
}
defineExpose({ getValue, begin })
</script>

<template>
    <div class="pane-content-container">
      <el-text style="margin-bottom: 10px">{{ PageNumber }} of {{ TotalPageNumber }}</el-text>
      <el-text size="large" style="font-weight: bold; color: #151740; text-align: center"
        >{{ props.CheckTask.label }}
      </el-text>
      <div style="font-weight: bold; color: #151740; text-align: center">
        <ReadMore :text="props.CheckTask.description" :maxChars="100" moreStr="read more" lessStr="read less" />
      </div>

      <div class="pane-content">
        <TextComponent 
          v-if="props.CheckTask.inputType == InputType.Text" 
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged" 
        />
        <TextareaComponent 
          v-if="props.CheckTask.inputType == InputType.TextArea" 
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged" 
        />
        <CheckboxComponent 
          v-if="props.CheckTask.inputType == InputType.CheckBox"
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged"   
        />
        <CheckboxNoExpectedComponent 
          v-if="props.CheckTask.inputType == InputType.CheckBoxNoExpected"
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged"   
        />
        <DropdownComponent 
          v-if="props.CheckTask.inputType == InputType.Select"
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged" 
        />
        <MessageComponent 
          v-if="props.CheckTask.inputType == InputType.Message" 
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
        />
        <TemperatureComponent
          v-if="isInputTypeTemperature(props.CheckTask.inputType)"
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged" 
        />
        <SignatureComponent 
          v-if="props.CheckTask.inputType == InputType.Signature" 
          v-model="value" 
          :min="min"
          :max="max"
          :options="options"
          @onValueChange="onValueChanged" 
        />
      </div>
      <div v-if="props.CheckTask.skippable" class="centered">
        <el-button class="btn-skip" @click="skip">Skip this step</el-button> 
      </div>
    </div>

</template>

<style scoped>
.centered{
  text-align:center;
  margin: 30px;
}

.pane-content-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.pane-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.bottom-buttons {
  border-radius: 0;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.report-issue-button {
  --el-button-bg-color: #dfae45;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #d38f36;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.cancel-button {
  --el-button-bg-color: #e1341e;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #e1341e;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.next-button {
  --el-button-bg-color: #79b342;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #79b342;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.previous-button {
  --el-button-bg-color: #009de0;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #009de0;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}

.btn-skip{
  font-size: 1.5em;
  margin-top: 10px;
}
</style>