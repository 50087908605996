import { ApiClient } from '@/apiClient'

export class UserService {
  apiClient: ApiClient = new ApiClient()

  async forgotPassword(email: string) {
    return await this.apiClient
      .forgotPassword(email)
      .then((response: any) => {
        return true;
      })
      .catch((_: any) => {
        return false;
      })
  }

  async resetPassword(oldPass: string, password: string) {
    return await this.apiClient
      .resetPassword(oldPass, password)
  }
}
