<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = defineProps<{
    text: string;
    maxChars: number;
    moreStr: string;
    lessStr: string;
}>();
const isReadMore = ref(false);
const formattedString = computed(() => {
    if (props.text.length > props.maxChars) {
        return isReadMore.value ? props.text : props.text.substring(0, props.maxChars);
    }
    return props.text;
});

const triggerReadMore = (rm: boolean) => {
    isReadMore.value = rm;
};
</script>

<template>
    <div>
		<p>
            <span v-html="formattedString"> </span>
			<span v-show="text.length > maxChars">
                <span v-if="!isReadMore">&hellip;</span>
				<a href="javascript:void(0)" id="readmore" class="text-sm" v-show="!isReadMore" v-on:click="triggerReadMore(true)">{{moreStr}}</a>
				<a href="javascript:void(0)" id="readmore" class="text-sm" v-show="isReadMore" v-on:click="triggerReadMore(false)">{{lessStr}}</a>
			</span>
		</p>
	</div>
</template>

<style scoped>
.text-sm{ font-size: .9em;}
</style>