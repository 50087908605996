import { defineStore } from 'pinia'
import type { Setting, TemperatureRange } from '@/interfaces/Setting'
import { computed, type ComputedRef, ref } from 'vue'
import { InputType } from '@/enums/InputType'


export const useSettingsStore = defineStore('settingsStore', () => {
  const settings = ref<Setting | null>(null)

  const setSettings = (newSettings: Setting) => {
    settings.value = newSettings
  }

  const getTemperatureSettings = (type: InputType) => {
    let range: TemperatureRange = { min: 0, max: 0}
    let defaultTemp = 0;
    if (settings.value !== undefined && settings.value !== null) {
      switch (type) {
        case InputType.FreezerTemp:
          range = settings.value.freezer!
          defaultTemp = -20.0;
          break;
          case InputType.FridgeTemp:
            range = settings.value.fridge!
            defaultTemp = 4.0;
            break;
          case InputType.CookTemp:
            range = settings.value.cooking!
            defaultTemp = 78.0;
            break;
          case InputType.HotHoldingTemp:
            range = settings.value.hotHolding!
            defaultTemp = 63.0;
            break;
          case InputType.ColdHoldingTemp:
            range = settings.value.coldHolding!
            defaultTemp = 0.0;
            break;
          case InputType.CoolingTemp:
            range = settings.value.cooling!
            defaultTemp = 5.0;
            break;
          case InputType.HotProbeTemp:
            range = settings.value.hotProbe!
            defaultTemp = 100.0;
            break;
          case InputType.ColdProbeTemp:
            range = settings.value.coldProbe!
            defaultTemp = 0.0;
            break;
          case InputType.RegenerationTemp:
            range = settings.value.regeneration!
            defaultTemp = 80.0;
            break;
      }
    }

    return {
      min: range.min,
      max: range.max,
      default: defaultTemp
    }
  }

  return {
    setSettings,
    getTemperatureSettings
  }
})
